import { LoadingButton } from "@mui/lab";
import { Box, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  useGetConfigsQuery,
  useUpdateConfigMutation,
} from "../../../../features/userConfig/userConfigApiSlice";
import SelectSound from "../SelectSound";
import { MessageSound } from "../../../BackDisplay/sounds";
import playImage from "../../../../assets/images/playbutton.jpg";
import playHoverImage from "../../../../assets/images/playbuttonhover.jpg";
import playClickImage from "../../../../assets/images/playbuttonclick.jpg";

const TopInterview = () => {
  const { data, isSuccess } = useGetConfigsQuery();
  const [updateConfig, { isLoading }] = useUpdateConfigMutation();
  const [over, setOVer] = useState("out");

  const {
    register,
    setValue,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm({
    interviewMessage: "",
    interviewSound: "",
    interviewDuration: 0,
  });

  useEffect(() => {
    if (isSuccess && data.length > 0) {
      setValue("interviewMessage", data[0].interviewMessage);
      setValue("interviewSound", data[0].interviewSound);
      setValue("interviewDuration", data[0].interviewDuration);
    }
  }, [isSuccess, data, setValue]);

  const submitHandler = (values) => {
    updateConfig({ id: data[0].id, data: values });
  };

  const playSound = () => {
    let audio = new Audio(MessageSound(watch("interviewSound")));
    audio.play();
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        gap={4}
        sx={{ py: "12px" }}
        alignItems={"center"}
      >
        <div>
          <Box sx={{ flex: 1 }}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              label="Default Text"
              variant="outlined"
              placeholder="Input here"
              defaultValue={"- is here to play"}
              fullWidth
              {...register("interviewMessage", {
                required: "This field is required!",
              })}
              onChange={() => {}}
              error={!!errors.interviewMessage}
              helperText={errors?.interviewMessage?.message || ""}
            />
          </Box>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ flex: 1, width: { xs: "100%", sm: "200px" } }}>
            <SelectSound
              value={watch("interviewSound")}
              setValue={(v) => setValue("interviewSound", v)}
            />
          </Box>
          <Box
            onClick={() => playSound()}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Box
              component="img"
              sx={{ cursor: "pointer" }}
              alt="logo"
              src={
                over === "down"
                  ? playClickImage
                  : over === "in"
                  ? playHoverImage
                  : playImage
              }
              onMouseDown={() => setOVer("down")}
              onMouseOver={() => setOVer("in")}
              onMouseOut={() => setOVer("out")}
              mx={3}
            />
          </Box>
        </div>
        <div>
          <Box sx={{ flex: 1 }}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              label="Duration (sec)"
              variant="outlined"
              placeholder={"input here"}
              fullWidth
              {...register("interviewDuration", {
                required: "This field is required!",
              })}
            />
          </Box>
        </div>
        <div style={{ width: 120 }}>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            type="submit"
            fullWidth
          >
            Save
          </LoadingButton>
        </div>
      </Stack>
    </form>
  );
};

export default TopInterview;
