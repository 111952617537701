import React, { useMemo, useState, useEffect, useRef } from "react";
import { useInnerSize } from "../../hooks/useInnerSize";
import Item from "./Item";
import SingleBackCamera from "../singleCamera/SingleBackCamera";

const MessageListingDisplay = ({
  setIsMessage,
  isMessage,
  muted,
  messages,
  splitted,
  config,
  primaryCamera,
  secondaryCamera,
}) => {
  const { height: windowHeight } = useInnerSize();
  const scrollRef = useRef(null);
  const [started, setStarted] = useState(false);

  const height = useMemo(() => {
    const calculateHeight = windowHeight / messages?.length;
    if (calculateHeight > windowHeight / 7) return calculateHeight;
    return windowHeight / 7;
  }, [messages, windowHeight]);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      scrollRef.current.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    const winScroll = () => {
      scrollRef.current.scrollBy({ top: windowHeight / 7, behavior: "smooth" });
    };

    if (started) {
      const scrollInterval = setInterval(() => {
        winScroll();
      }, 5000);
      return () => clearInterval(scrollInterval);
    }
  }, [started, windowHeight]);

  useEffect(() => {
    if (messages?.length) setStarted(true);
    if (!messages?.length) setStarted(false);
    if (messages?.length && !isMessage) {
      setIsMessage(true);
    }
    if (!messages?.length && isMessage) {
      setIsMessage(false);
    }
  }, [messages, setIsMessage, isMessage]);

  return (
    <div
      style={{ height: windowHeight }}
      className={"no-scrollbar"}
      ref={scrollRef}
      onScroll={handleScroll}
    >
      {messages?.length === 0 ? (
        <SingleBackCamera
          singleCamera={splitted ? secondaryCamera : primaryCamera}
        />
      ) : (
        messages.map((item) => {
          return (
            <Item
              muted={muted}
              item={item}
              background={
                item.category === "checkin"
                  ? "#489462"
                  : item.category === "checkout"
                  ? "#9C2136"
                  : item.category === "interview"
                  ? "#F4364C"
                  : "#81B0D2"
              }
              logo={item.imageUrl}
              key={item.id}
              text={item.message}
              height={height}
              sound={
                item.category === "checkin"
                  ? config[0].checkInSound
                  : item.category === "checkout"
                  ? config[0].checkOutSound
                  : item.category === "interview"
                  ? config[0].interviewSound
                  : item.category === "general"
                  ? config[0].generalSound
                  : config[0].scheduledSound
              }
              diff={item.diff}
            />
          );
        })
      )}
    </div>
  );
};

export default MessageListingDisplay;
