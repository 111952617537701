import { Divider, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, Navigate, useLocation } from 'react-router-dom';

const AdminLayout = ({ children }) => {
  const { is_admin } = useSelector((state) => state?.auth?.user);

  if (!is_admin) return <Navigate to='/messages' />;

  return (
    <>
      <Typography
        variant='h3'
        sx={{ textTransform: 'uppercase', mb: '15px' }}
        component={'h2'}
        fontFamily="MissMagnolia"
        letterSpacing={2}
      >
        Admin Portal
      </Typography>
      <Divider />

      <Box as='div' sx={{ mt: '15px', pb: '0px' }}>
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={'15px'}>
          <LinkItem text='Locations' url='/admin/locations' />
          <LinkItem text='Default Messages' url='/admin/default-messages' />
          <LinkItem text='Default Images' url='/admin/default-images' />
        </Stack>
      </Box>
      <Divider />
      <div>{children}</div>
    </>
  );
};

const LinkItem = ({ url, text }) => {
  const { pathname } = useLocation();
  return (
    <Box
      as={Link}
      sx={{
        display: 'block',
        textDecoration: 'none',
        color: pathname === url ? '#81b1d9' : '#4f4f4f',
        fontSize: '27px',
        fontWeight: 600,
        fontFamily: "MissMagnolia",
        textTransform: "uppercase",
        letterSpacing: 2,
        transition: 'all 0.5s',
        '&:hover': {
          color: '#81b1d9',
        },
        borderBottom: pathname === url ? '2px solid #81b1d9' : 0,
        pb: '5px',
        pr: '15px',
      }}
      to={url}
    >
      {text}
    </Box>
  );
};

export default AdminLayout;
