import beeps from "../../assets/sounds/3beeps.wav";
import bugle from "../../assets/sounds/Bugle.wav";
import cartoon from "../../assets/sounds/CartoonAccent.wav";
import checkIn from "../../assets/sounds/Check In.wav";
import checkOut from "../../assets/sounds/Check Out.wav";
import corporate from "../../assets/sounds/Corporate Notification Sound.wav";
import crowd from "../../assets/sounds/CrowdCheering.wav";
import game from "../../assets/sounds/Game Upgrade.wav";
import general from "../../assets/sounds/General Message.wav";
import happy from "../../assets/sounds/Happy Win Game.wav";
import jubilant1 from "../../assets/sounds/Jubilant Fanfare.wav";
import jubilant2 from "../../assets/sounds/JubilantFanfare.wav";
import organ1 from "../../assets/sounds/OrganFanfare.wav";
import organ2 from "../../assets/sounds/OrganMusic.wav";
import rock1 from "../../assets/sounds/Rock & Roll Prize.wav";
import scheduled from "../../assets/sounds/Scheduled.wav";
import rock2 from "../../assets/sounds/The Rock n Roll Logo.wav";
import trumpet1 from "../../assets/sounds/Trumpet Charge Military Bugle.wav";
import trumpet2 from "../../assets/sounds/TrumpetComedy.wav";
import trumpet3 from "../../assets/sounds/TrumpetMusic.wav";
import victory from "../../assets/sounds/Victory Fanfare.wav";
import winsound1 from "../../assets/sounds/winsound.wav";
import winsound2 from "../../assets/sounds/winsound_bkp.wav";

export const MessageSound = (sound) => {
  if (sound === "3beeps") {
    return beeps;
  } else if (sound === "Bugle") {
    return bugle;
  } else if (sound === "CartoonAccent") {
    return cartoon;
  } else if (sound === "Check In") {
    return checkIn;
  } else if (sound === "Check Out") {
    return checkOut;
  } else if (sound === "Corporate Notification Sound") {
    return corporate;
  } else if (sound === "CrowdCheering") {
    return crowd;
  } else if (sound === "Game Upgrade") {
    return game;
  } else if (sound === "General Message") {
    return general;
  } else if (sound === "Happy Win Game") {
    return happy;
  } else if (sound === "Jubilant Fanfare") {
    return jubilant1;
  } else if (sound === "JubilantFanfare") {
    return jubilant2;
  } else if (sound === "OrganFanfare") {
    return organ1;
  } else if (sound === "OrganMusic") {
    return organ2;
  } else if (sound === "Rock & Roll Prize") {
    return rock1;
  } else if (sound === "The Rock n Roll Logo") {
    return rock2;
  } else if (sound === "Scheduled") {
    return scheduled;
  } else if (sound === "Trumpet Charge Military Bugle") {
    return trumpet1;
  } else if (sound === "TrumpetComedy") {
    return trumpet2;
  } else if (sound === "TrumpetMusic") {
    return trumpet3;
  } else if (sound === "Victory Fanfar") {
    return victory;
  } else if (sound === "winsound") {
    return winsound1;
  } else if (sound === "winsound_bkp") {
    return winsound2;
  } else {
    return general;
  }
};
