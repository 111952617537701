import { Box, Grid } from "@mui/material";
import axios from "axios";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import useInnerSize from "../../hooks/useInnerSize";
import SingleCamera from "./SingleCamera";
import configData from "../../config.json";

const LAYOUT_ITEMS = [
  {
    id: 1,
    columnCount: 1,
    rowCount: 1,
    orientation: null,
  },
  {
    id: 5,
    columnCount: 3,
    rowCount: 2,
    orientation: "horizontal",
  },
  {
    id: 2,
    columnCount: 3,
    rowCount: 3,
    orientation: "horizontal",
  },
  {
    id: 3,
    columnCount: 4,
    rowCount: 3,
    orientation: "horizontal",
  },
  {
    id: 4,
    columnCount: 4,
    rowCount: 4,
    orientation: "horizontal",
  },
];

const LAYOUT_ITEMS_WITH_NAME = LAYOUT_ITEMS.map((item) => {
  const { orientation, rowCount, columnCount } = item;
  const or = orientation ? `_${orientation}` : "";
  const name = `cam_${rowCount * columnCount}${or}`;
  return { ...item, name };
});

const baseURL = configData.SERVER_URL;
const RightSide1 = ({ config, viewerId }) => {
  const { height, width } = useInnerSize();
  const [camerasById, setCamerasById] = useState([]);

  const getCameras = useCallback(async () => {
    axios
      .get(`${baseURL}cameras`, {
        headers: {
          userid: viewerId,
        },
      })
      .then((res) => {
        let temp = [];
        temp = res.data.filter((e) => e.backDisplay === 0);
        setCamerasById(temp);
      })
      .catch((err) => console.log("Cameras Err::", err));
  }, [viewerId]);

  useEffect(() => {
    if (viewerId) {
      getCameras();
    }
  }, [viewerId, getCameras]);

  const calculateWidth = useMemo(() => {
    return width * 0.8;
  }, [width]);

  const { row, col } = useMemo(() => {
    if (config.length === 0) return { row: 1, col: 1 };

    const name = config[0].cameraDisplay;
    const cameraData = LAYOUT_ITEMS_WITH_NAME.find((i) => i.name === name);

    return {
      row: cameraData?.rowCount || 3,
      col: cameraData?.columnCount || 3,
    };
  }, [config]);

  return (
    <Box
      sx={{ width: calculateWidth, height: calculateWidth, overflow: "hidden" }}
    >
      <Grid container>
        {camerasById.slice(0, col * row).map((e) => (
          <Grid item xs={12 / col} key={e.id}>
            <SingleCamera
              config={config}
              height={height / row}
              isGroup={e?.isGroup || false}
              name={e?.cameraName}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default RightSide1;
