import axiosInstance from "../../utils/axiosInstance";
import { getAuthUserId } from "../../utils/auth";
import axios from "axios";
import configData from "../../config.json";
import { getToken } from "../../utils/token";

export async function getArchivesApi() {
  const token = getToken();
  const baseURL = configData.SERVER_URL;
  const userId = getAuthUserId();

  const axiosIns = axios.create({
    baseURL,
    headers: {
      userid: userId,
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json",
    },
  });
  const temp = await axiosIns.get("/archive");
  return temp;
}
export async function getMessagesApi() {
  const token = getToken();
  const baseURL = configData.SERVER_URL;
  const userId = getAuthUserId();

  const axiosIns = axios.create({
    baseURL,
    headers: {
      userid: userId,
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json",
    },
  });
  const temp = await axiosIns.get("/messages");
  return temp;
  // return axiosInstance.get('/messages');
}
export async function getMessageApi(id) {
  const token = getToken();
  const baseURL = configData.SERVER_URL;
  const userId = getAuthUserId();

  const axiosIns = axios.create({
    baseURL,
    headers: {
      userid: userId,
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json",
    },
  });
  const temp = await axiosIns.get(`/messages/${id}`);
  return temp;
  // return axiosInstance.get(`/messages/${id}`);
}

export async function getArchiveApi(id) {
  const token = getToken();
  const baseURL = configData.SERVER_URL;
  const userId = getAuthUserId();

  const axiosIns = axios.create({
    baseURL,
    headers: {
      userid: userId,
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json",
    },
  });
  const temp = await axiosIns.get(`/archive/${id}`);
  return temp;
  // return axiosInstance.get(`/messages/${id}`);
}

export async function deleteMessagesApi(id) {
  const token = getToken();
  const baseURL = configData.SERVER_URL;
  const userId = getAuthUserId();
  return axios.delete(`${baseURL}messages/${id}`, {
    headers: {
      userid: userId,
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json",
    },
  });
}
export async function addMessageApi(data) {
  const token = getToken();
  // const baseURL = configData.SERVER_URL;
  const baseURL = configData.MESSAGES_SERVER_URL;
  const userId = getAuthUserId();

  const axiosIns = axios.create({
    baseURL,
    headers: {
      userid: userId,
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json",
    },
  });
  const temp = await axiosIns.post("/messages", data);
  return temp;

  //return axiosInstance.post(`/messages/`, data);
}
export async function updateMessageApi({ id, data }) {
  return axiosInstance.put(`/messages/${id}`, data);
}
