/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import useInnerSize from "../../hooks/useInnerSize";
import CheckInLogo from "../../assets/images/IMG_2822.png";
import CheckOutLogo from "../../assets/images/IMG_2823.png";
import InterviewLogo from "../../assets/images/IMG_2824.png";
import ScheduledLogo from "../../assets/images/IMG_2825.png";
import { MessageSound } from "./sounds";
// import useSound from 'use-sound';

const Item = ({ item, logo, background, text, height, sound, diff,muted }) => {
  const [resFSize, setResFSize] = useState(0);
  const [resMTop, setResMTop] = useState(0);
  const [resLHeight, setResLHeight] = useState(0);

  const { width } = useInnerSize();
  useEffect(() => {
    calcFontSize();
  }, [resFSize, text, width, height]);

  const calcFontSize = () => {
    let area = height * width * 0.65;
    let ourTextLen = 0;
    if(text){
      ourTextLen = text?.length;
    }
    
    if (ourTextLen < 12) ourTextLen = 12;

    let charArea = Math.floor(0.75 * Math.sqrt(area / ourTextLen));
    let fSize = charArea + "px";
    let marTop = charArea * 0.005;
    let lHeight = charArea - marTop * 8;
    setResFSize(fSize);
    setResMTop(marTop);
    setResLHeight(lHeight);
  };

  useEffect(() => {
    let audio = new Audio(MessageSound(sound));
    if (muted === 0 || muted === '0'){
      audio.play();
    }
    
  }, [text]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: height,
      }}
    >
      <Box
        as={"div"}
        sx={{
          background: background,
          width: "100%",
          height: "20px",
          maxHeight: 100,
          minHeight: 20,
          boxSizing: "border-box",
          textAlign: "center",
          py: "10px",
          color: "#fff",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: "20px",
        }}
      >
        <Box as={"div"}></Box>
        <Box as={"h4"}></Box>
        {/* <Box as={"h2"}>
          {moment(new Date(item.lastSent)).add(3,'hours').format("h:mm A, MMM Do YYYY")}
        </Box> */}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          background: background,
          width: "100%",
          height: height,
        }}
      >
        <Box
          component="img"
          sx={{
            height: height * 0.45,
            width: height * 0.45,
            mx: 5,
            maxHeight: 150,
            maxWidth: 150,
            objectFit: "cover",
          }}
          alt="logo"
          src={
            logo
              ? logo
              : item.category === "checkin"
              ? CheckInLogo
              : item.category === "checkout"
              ? CheckOutLogo
              : item.category === "interview"
              ? InterviewLogo
              : ScheduledLogo
          }
        />
        <Box
          sx={{
            display: "flex",
            alignItems: 'center',
            color: "#000",
            width: "100%",
            height: "100%",
            background: "#fff",
          }}
          padding={0}
          px={3}
          className={diff > 10 ? "none-blink" : "blink"}
        >
          <Typography
            sx={{
              fontSize: resFSize,
              marginTop: resMTop + "px",
              color: "#000",
              textAlign: "left",
            }}
            fontFamily="Oswald"
            textTransform={"uppercase"}
            lineHeight={resLHeight + "px"}
            fontWeight={500}
            letterSpacing={7}
          >
            {text}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Item;
