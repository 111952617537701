import { LoadingButton } from "@mui/lab";
import { Box, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect } from "react";
import Card from "../../Card";

import moment from "moment";
import { useForm } from "react-hook-form";
import { useAddElementMutation } from "../../../../features/userElement/userElementApiSlice";
import { getAuthUserId } from "../../../../utils/auth";
import ListTable from "./List";
import TopInterview from "./TopInterview";

const QuickSend = () => {
  const [addElement, { isLoading, isSuccess }] = useAddElementMutation();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  useEffect(() => {
    if (isSuccess) {
      reset();
    }
  }, [reset, isSuccess]);

  const submitHandler = (values) => {
    addElement({
      userId: getAuthUserId(),
      type: "interview",
      ...values,
      deletedAt: moment(),
    });
  };
  return (
    <div>
      <Card title={"Interview"} color="#F4364C">
        <TopInterview />
        <form onSubmit={handleSubmit(submitHandler)}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            gap={4}
            sx={{ py: "12px" }}
            alignItems={"center"}
          >
            <div>
              <Box sx={{ flex: 1 }}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Create Interview"
                  variant="outlined"
                  fullWidth
                  {...register("displayName", {
                    required: "This field is required!",
                  })}
                  error={!!errors.displayName}
                  helperText={errors?.displayName?.message || ""}
                />
              </Box>
            </div>

            <div style={{ width: 120 }}>
              <LoadingButton
                loading={isLoading}
                variant="contained"
                type="submit"
                fullWidth
                //   sx={{ background: '#82da73' }}
              >
                Add
              </LoadingButton>
            </div>
          </Stack>
        </form>

        <ListTable />
      </Card>
    </div>
  );
};

export default QuickSend;
