import { useState, useEffect } from "react";
import io from "socket.io-client";
import configData from "../config.json";

//TODO: consider moving this to a Context/Provider so we can ensure there is only ever one socketIO connection
export default function useMessages({ viewerId }) {
  const [messages, setMessages] = useState([]);
  const [apiMessages, setApiMessages] = useState([]);

  useEffect(() => {
    const socketOptions = {
      reconnection: true, // Whether to reconnect automatically
      reconnectionAttempts: Infinity, // Number of reconnection attempts before giving up
      reconnectionDelay: 1000, // How long to wait initially before attempting a new reconnection
      reconnectionDelayMax: 5000, // Maximum amount of time to wait between reconnection attempts. Each attempt increases the reconnection delay by 2x along with a randomization factor
      randomizationFactor: 0.5, // Randomization factor between 0 and 1. The default value is 0.5, which means that the actual reconnection delay will grow by 50% with each attempt.
    };
    if (viewerId !== 0) {
      const socket = io(configData.MESSAGES_SERVER_URL, {
        ...socketOptions,
        transports: ["websocket", "polling"],
      });

      socket.on("connect", () => {
        if (socket.recovered) {
          // any event missed during the disconnection period will be received now
        } else {
          socket.emit("register", viewerId);
        }
      });

      socket.on(
        "message",
        (message) => {
          if (message.type === "normal") {
            // Add the message to the state
            setMessages((prevMessages) => [...prevMessages, message.content]);
            // Remove the message after its duration
            setTimeout(() => {
              setMessages((prevMessages) =>
                prevMessages.filter((m) => m.id !== message.content.id)
              );
            }, message.duration * 1000);
          } else if (message.type === "api") {
            setApiMessages((prevMessages) => [
              ...prevMessages,
              message.content,
            ]);
          } else if (message.type === "system") {
            if (message.content === "reset") {
              window.location.reload();
            }
          }
        },
        20000
      );

      // Reconnection logic
      socket.on("reconnecting", (attemptNumber) => {
        console.log("Reconnecting...", attemptNumber);
      });

      socket.on("reconnect_error", (error) => {
        console.error("Reconnection error:", error);
      });

      socket.on("reconnect_failed", () => {
        console.error("Reconnect failed.");
        //TODO: Update this hook so that it uses a ref for the socketIO instance and can attempt to reset entirely here.
      });
    }
  }, [viewerId]);

  return { messages, setMessages, apiMessages, setApiMessages };
}
