import axiosInstance from "../../utils/axiosInstance";
import { getAuthUserId } from "../../utils/auth";
import axios from "axios";
import configData from "../../config.json";
import { getToken } from "../../utils/token";

export async function getStreamApi() {
  const token = getToken();
  const baseURL = configData.SERVER_URL;
  const userId = getAuthUserId();

  const axiosIns = axios.create({
    baseURL,
    headers: {
      userid: userId,
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json",
    },
  });
  const temp = await axiosIns.get("/cameras?list=1");
  return temp;
}
export async function getSingleStreamApi(id) {
  return axiosInstance.get(`/cameras/${id}`);
}

export async function deleteStreamApi(id) {
  return axiosInstance.delete(`/cameras/${id}`);
}
export async function addStreamApi(data) {
  return axiosInstance.post(`/cameras/`, data);
}
export async function updateStreamApi({ id, data }) {
  return axiosInstance.put(`/cameras/${id}`, data);
}
