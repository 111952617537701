import { Box } from "@mui/material";
import React, { useMemo } from "react";

const SingleCamera = ({ config, height, name, isGroup }) => {
  const cameraUrl = useMemo(() => {
    if (!isGroup) {
      return `&name=${name}&cam=${name}`;
    }
    return `&group=${name}`;
  }, [name, isGroup]);
  // if (isLoading || !isSuccess) return null;
  return (
    <Box sx={{ height }}>
      <Box
        sx={{
          width: "100%",
          boxSizing: "border-box",
        }}
        className=""
        as="div"
      >
        <Box
          sx={{
            border: "1px solid #000",
            height: height - 2,
          }}
        >
          {/* <portals.OutPortal node={portalNode} /> */}
          <iframe
            src={`${config?.[0]?.cameraUrl || "/?"}${cameraUrl}`}
            title={name}
            frameBorder="0"
            width="100%"
            height="100%"
          ></iframe>
        </Box>
      </Box>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "900px",
        }}
      ></div>
    </Box>
  );
};

export default SingleCamera;
