import React from "react";
import CameraLayout from "../../components/BackDisplay/CameraLayout";

const BackDisplay = ({ muted, splitted }) => {
  return (
    <>
      <CameraLayout muted={muted} splitted={splitted} />
    </>
  );
};

export default BackDisplay;
