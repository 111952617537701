import { useState, useEffect, useCallback } from "react";

import configData from "../../config.json";
import MessageContent from "./MessageContent";

import { useParams } from "react-router-dom";
import axios from "axios";
import React from "react";
import * as portals from "react-reverse-portal";

const baseURL = configData.SERVER_URL;

function CameraLayout({ muted, splitted }) {
  const [cameraUrl, setCameraUrl] = useState("");
  const [splitcameraUrl, setSplitCameraUrl] = useState("");

  const { params, cam } = useParams();

  const primaryCamera = React.useMemo(
    () =>
      portals.createHtmlPortalNode({
        attributes: { style: "width: 100%; height: 100%; overflow: hidden" },
      }),
    []
  );

  const secondaryCamera = React.useMemo(
    () =>
      portals.createHtmlPortalNode({
        attributes: { style: "height: 100%; overflow: hidden" },
      }),
    []
  );
  // const [checksum,setChecksum] = useState(moment(new Date()).format('Y-m-d H:i'));

  const getCameraUrl = useCallback(async () => {
    axios
      .get(`${baseURL}back-display?account=${params}`) //&cam=${cam}
      .then((res) => {
        setCameraUrl(res.data.camera);
      })
      .catch((err) => {
        console.log("Err::", err);
        setCameraUrl("Houston, tx");
      });
  }, [params]);

  useEffect(() => {
    if (params !== undefined) {
      getCameraUrl();
    } else {
      setCameraUrl("");
    }
  }, [getCameraUrl, params]);

  useEffect(() => {
    if (splitted && cameraUrl !== "") {
      let splitCamera = new URL(cameraUrl);
      splitCamera.searchParams.set("cam", cam);
      setSplitCameraUrl(splitCamera.href);
    }
  }, [splitted, cam, cameraUrl]);

  return (
    <div>
      <portals.InPortal node={primaryCamera}>
        <iframe
          title="Main Display"
          src={cameraUrl}
          width="100%"
          height="100%"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </portals.InPortal>
      <portals.InPortal node={secondaryCamera}>
        {splitcameraUrl ? (
          <iframe
            title="Secondary Display"
            src={splitcameraUrl}
            width="100%"
            height="100%"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        ) : (
          ""
        )}
      </portals.InPortal>
      <MessageContent
        cameraUrl={cameraUrl}
        muted={muted}
        splitted={splitted}
        primaryCamera={primaryCamera}
        secondaryCamera={secondaryCamera}
        cam={cam}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "900px",
        }}
      ></div>
    </div>
  );
}

export default CameraLayout;
