import { useEffect, useState } from "react";
import ApiContent from "./ApiContent";

export default function LargeMessageOverlay({
  messages,
  setMessages,
  config,
  muted,
}) {
  const [displayMessage, setDisplayMessage] = useState(null);

  /*
   * Messages is a queue where we always display the first message in that queue.
   * When we are done displaying a message, we remove it from the queue.
   */
  useEffect(() => {
    const currentMessage = messages?.length > 0 ? messages[0] : null;
    if (displayMessage === null && currentMessage !== null) {
      setDisplayMessage(currentMessage);
      return;
    }

    if (displayMessage === null) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setDisplayMessage(null);
      setMessages(
        (prevMessages) =>
          prevMessages?.filter((m) => m?.id !== displayMessage?.id) ?? []
      );
    }, displayMessage?.duration * 1000);

    return () => clearTimeout(timeoutId);
  }, [displayMessage, messages, setMessages]);

  if (displayMessage === null) {
    return null;
  }

  return (
    <ApiContent
      config={config}
      muted={muted}
      messages={displayMessage}
      show={displayMessage !== null}
    />
  );
}
