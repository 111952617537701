import { LoadingButton } from "@mui/lab";
import { Box, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  useGetConfigsQuery,
  useUpdateConfigMutation,
} from "../../../../features/userConfig/userConfigApiSlice";
import Card from "../../Card";
import SelectSound from "../SelectSound";
import { MessageSound } from "../../../BackDisplay/sounds";
import playImage from "../../../../assets/images/playbutton.jpg";
import playHoverImage from "../../../../assets/images/playbuttonhover.jpg";
import playClickImage from "../../../../assets/images/playbuttonclick.jpg";

const CheckOut = () => {
  const { data, isSuccess } = useGetConfigsQuery();
  const [updateConfig, { isLoading }] = useUpdateConfigMutation();
  const [over, setOVer] = useState("out");

  const {
    register,
    setValue,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm({
    checkOutMessage: "",
    checkOutSound: "",
    checkOutDuration: 0,
  });

  useEffect(() => {
    if (isSuccess && data.length > 0) {
      setValue("checkOutMessage", data[0].checkOutMessage);
      setValue("checkOutSound", data[0].checkOutSound);
      setValue("checkOutDuration", data[0].checkOutDuration);
    }
  }, [isSuccess, data, setValue]);

  const submitHandler = (values) => {
    updateConfig({ id: data[0].id, data: values });
  };

  const playSound = () => {
    let audio = new Audio(MessageSound(watch("checkOutSound")));
    audio.play();
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Card title={"Check Out"} color="#9C2136">
        <Stack
          direction={{ xs: "column", sm: "row" }}
          gap={4}
          sx={{ py: "12px" }}
          alignItems={"center"}
        >
          <div>
            <Box sx={{ flex: 1 }}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                label="Default Text"
                variant="outlined"
                placeholder="Input here"
                defaultValue={"- is here to play"}
                fullWidth
                {...register("checkOutMessage", {
                  required: "This field is required!",
                })}
                onChange={() => {}}
                error={!!errors.checkOutMessage}
                helperText={errors?.checkOutMessage?.message || ""}
              />
            </Box>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ flex: 1, width: { xs: "100%", sm: "200px" } }}>
              <SelectSound
                value={watch("checkOutSound")}
                setValue={(v) => setValue("checkOutSound", v)}
              />
            </Box>
            <Box
              onClick={() => playSound()}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Box
                component="img"
                sx={{ cursor: "pointer" }}
                alt="logo"
                src={
                  over === "down"
                    ? playClickImage
                    : over === "in"
                    ? playHoverImage
                    : playImage
                }
                onMouseDown={() => setOVer("down")}
                onMouseOver={() => setOVer("in")}
                onMouseOut={() => setOVer("out")}
                mx={3}
              />
            </Box>
          </div>
          <div>
            <Box sx={{ flex: 1 }}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                type="number"
                label="Duration (sec)"
                variant="outlined"
                placeholder={"input here"}
                defaultValue={90}
                fullWidth
                {...register("checkOutDuration", {
                  required: "This field is required!",
                })}
              />
            </Box>
          </div>
          <div style={{ width: 120 }}>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              type="submit"
              fullWidth
              //   sx={{ background: '#82da73' }}
            >
              Save
            </LoadingButton>
          </div>
        </Stack>
      </Card>
    </form>
  );
};

export default CheckOut;
