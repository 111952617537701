import axios from "axios";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import useInnerSize from "../../hooks/useInnerSize";
import configData from "../../config.json";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const baseURL = configData.SERVER_URL;
const LeftSide = ({ config, viewerId }) => {
  const { height } = useInnerSize();
  const [photosById, setPhotosById] = useState([]);

  const getPhotos = useCallback(async () => {
    axios
      .get(`${baseURL}slides`, {
        headers: {
          userid: viewerId,
        },
      })
      .then((res) => {
        setPhotosById(res.data);
      })
      .catch((err) => console.log("Slides Err::", err));
  }, [viewerId]);

  useEffect(() => {
    if (viewerId) {
      getPhotos();
    }
  }, [viewerId, getPhotos]);

  const formatePhotos = useMemo(() => {
    if (photosById.length === 0) return [];
    return photosById.reduce((acc, cur) => {
      const file = cur.file_name
        ? `${configData.SERVER_URL}uploads/${cur.file_name}`
        : "";
      acc.push({ ...cur, file });
      return acc;
    }, []);
  }, [photosById]);

  const calculateHeight = useMemo(() => {
    const slideShowPosition = config[0]?.slideShowPosition;
    if (slideShowPosition === "NONE") return height;
    return height - (height / 100) * 70;
  }, [height, config]);

  return (
    <Box
      sx={{
        height: calculateHeight,
        width: calculateHeight,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Swiper
        pagination={true}
        autoplay={{
          delay: config[0]?.slideShowDuration * 1000,
        }}
        navigation={true}
        loop={true}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        {formatePhotos.map((item, i) => (
          <SwiperSlide key={i}>
            <Item
              item={item}
              imageHeight={calculateHeight}
              style={{ height: calculateHeight, width: calculateHeight }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

function Item({ imageHeight = 300, item }) {
  return (
    <>
      <Box
        as="div"
        sx={{
          width: "100%",
          height: imageHeight - 10,
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <img
          alt="slide"
          src={item.file}
          crossOrigin="anonymous"
          style={{ height: "100%", width: "100%", objectFit: "contain" }}
          loading="lazy"
        />
        <div class="swiper-lazy-preloader"></div>
      </Box>
    </>
  );
}

export default LeftSide;
