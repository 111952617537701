import { Box, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useMemo } from "react";
import { FaPen, FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { activeEditMode } from "../../../features/message/messageSlice";
import { deleteMessageAsync } from "../../../features/message/thunks";
function formatIsoDateStringWithoutDate(value) {
  let pmam = "AM";
  let currhr = parseInt(value.substr(11, 2), 10); // Get the hour part and parse it as an integer

  if (currhr >= 12) {
    pmam = "PM";
    if (currhr > 12) {
      currhr -= 12; // Convert to 12-hour format
    }
  } else if (currhr === 0) {
    currhr = 12; // If it's 00 hours, convert to 12 AM
  }

  // Format the hour to always be 2 digits
  let formattedHour = currhr.toString().padStart(2, "0");

  // Replace the T with a space, extract the relevant part, and append AM/PM
  let formattedString = value.replace("T", " ").substr(0, 19);
  formattedString =
    formattedString.substr(0, 11) +
    formattedHour +
    formattedString.substr(13) +
    " " +
    pmam;

  return `${formattedString}`;
}

const columns = [
  { headerName: "Message:", field: "message", width: 250 },
  {
    headerName: "Date and Time",
    field: "dateTime",
    width: 450,
    renderCell: ({ value }) => {
      return formatIsoDateStringWithoutDate(value);
    },
  },
  {
    headerName: "Days",
    field: "days",
    width: 250,
    renderCell: ({ value }) => {
      const DAYS = {
        1: "Mon",
        2: "Tue",
        3: "Wed",
        4: "Thu",
        5: "Fri",
        6: "Sat",
        0: "Sun",
      };
      let text = "";
      if (value !== null && value !== undefined) {
        text = value
          .toString()
          .split("")
          .map((i) => DAYS[i])
          .join(", ");
      }

      return text;
    },
  },
  { headerName: "Duration", field: "duration", width: 100 },
  {
    headerName: "Action",
    width: 100,
    sortable: false,
    renderCell: ({ row }) => {
      const styles = {
        color: "red",
        cursor: "pointer",
        ":hover": { color: "#6c94dc" },
      };
      return (
        <>
          {!row.isArchived ? (
            <Stack direction={"row"} spacing={1}>
              <Box
                component={FaPen}
                sx={styles}
                onClick={() =>
                  row.dispatch(activeEditMode({ data: row, type: "schedule" }))
                }
              />
              <Box
                component={FaTrashAlt}
                sx={styles}
                onClick={() => row.dispatch(deleteMessageAsync(row.id))}
              />
            </Stack>
          ) : null}
        </>
      );
    },
  },
];

export default function Table() {
  const [itemPerPage, setItemPerPage] = React.useState(10);

  const dispatch = useDispatch();

  const { data: messages } = useSelector((state) => state.messages);

  const scheduleMessages = useMemo(() => {
    // return whatever data you want from redux state
    if (messages.length <= 0) return [];

    return messages.reduce((acc, cur) => {
      if (cur.days || cur.days === 0 || cur.schedule) {
        acc.push({ ...cur, dispatch });
      }
      // acc.push({ ...cur, dispatch });
      return acc;
    }, []);
  }, [messages, dispatch]);

  return (
    <Box sx={{ mt: 4 }}>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Box sx={{ flexGrow: 1 }}>
          <DataGrid
            rows={scheduleMessages}
            columns={columns}
            disableSelectionOnClick
            disableColumnMenu
            pageSize={itemPerPage}
            onPageSizeChange={(newPageSize) => setItemPerPage(newPageSize)}
            rowsPerPageOptions={[5, 10, 20, 30, 50]}
            pagination
            style={{ border: "none" }}
            autoHeight
          />
        </Box>
      </Box>
    </Box>
  );
}
