import axios from "axios";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import useInnerSize from "../../hooks/useInnerSize";
import configData from "../../config.json";
import logo from "../../assets/images/headSlider.png";
import bottomLogo from "../../assets/images/bottom-romp.png";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const baseURL = configData.SERVER_URL;
const LeftSide1 = ({ config, viewerId }) => {
  const { height, width } = useInnerSize();
  const [photosById, setPhotosById] = useState([]);
  const getPhotos = useCallback(async () => {
    axios
      .get(`${baseURL}slides`, {
        headers: {
          userid: viewerId,
        },
      })
      .then((res) => {
        setPhotosById(res.data);
      })
      .catch((err) => console.log("Slides Err::", err));
  }, [viewerId]);

  useEffect(() => {
    if (viewerId) {
      getPhotos();
    }
  }, [viewerId, getPhotos]);

  const formatePhotos = useMemo(() => {
    if (photosById.length === 0) return [];
    return photosById.reduce((acc, cur) => {
      const file = cur.file_name
        ? `${configData.SERVER_URL}uploads/${cur.file_name}`
        : "";
      acc.push({ ...cur, file });
      return acc;
    }, []);
  }, [photosById]);

  const calculateWidth = useMemo(() => {
    return width * 0.2;
  }, [width]);

  return (
    <Box
      sx={{
        height: height,
        width: calculateWidth,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box component="img" alt="logo" src={logo} width={"80%"} />
      </Box>
      <Box sx={{ height: "5px", visibility: "hidden" }}>
        {config[0].slideShowDuration * 10}
      </Box>
      <Swiper
        pagination={true}
        autoplay={{
          delay: config[0]?.slideShowDuration * 1000,
          // delay: 3000,
        }}
        navigation={true}
        loop={true}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        {formatePhotos.map((item, i) => (
          <SwiperSlide key={i}>
            <Item
              item={item}
              imageWidth={calculateWidth}
              imageHeight={calculateWidth}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box component="img" alt="logo" src={bottomLogo} width={"70%"} />
      </Box>
    </Box>
  );
};

function Item({ imageWidth = 300, item }) {
  return (
    <>
      <Box
        as="div"
        sx={{
          height: imageWidth - 10,
          width: imageWidth - 10,
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <img
          alt="slide"
          src={item.file}
          crossOrigin="anonymous"
          style={{ height: "100%", width: "100%", objectFit: "contain" }}
          loading="lazy"
        />
        <div class="swiper-lazy-preloader"></div>
      </Box>
    </>
  );
}

export default LeftSide1;
