import { Stack } from "@mui/system";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSlideShowAsync } from "../../../../features/slideShow/thunks";
import Card from "../../Card";
import Add from "./Add";
import Item from "./Item";
import configData from "../../../../config.json";

const PhotoImages = () => {
  const { photos } = useSelector((state) => state.slide);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSlideShowAsync());
  }, [dispatch]);

  const formatePhotos = useMemo(() => {
    if (photos.length === 0) return [];
    return photos.reduce((acc, cur) => {
      const file = cur.file_name
        ? `${configData.SERVER_URL}uploads/${cur.file_name}`
        : "";
      acc.push({ ...cur, file });
      return acc;
    }, []);
  }, [photos]);

  return (
    <div>
      <Card title="Photo Images">
        <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
          {formatePhotos.map((item) => (
            <Item key={item.id} id={item.id} image={item.file} />
          ))}
          <Add />
        </Stack>
      </Card>
    </div>
  );
};

export default PhotoImages;
