import axios from "axios";
import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import useInnerSize from "../../hooks/useInnerSize";
import LeftSide from "./LeftSide";
import RightSide from "./RightSide";
import { useParams } from "react-router-dom";
import configData from "../../config.json";
import LeftSide1 from "./LeftSide1";
import RightSide1 from "./RightSide1";

const baseURL = configData.SERVER_URL;
const AllCameraListContainer = () => {
  const { height } = useInnerSize();
  const [viewerId, setViewerId] = useState();
  const [config, setConfig] = useState();

  const { params } = useParams();

  const getId = useCallback(async () => {
    axios
      .get(`${baseURL}back-display?account=${params}`)
      .then((res) => {
        setViewerId(res.data.id);
      })
      .catch((err) => {
        console.log("Err::", err);
        setViewerId(0);
      });
  }, [params]);

  const getConfig = useCallback(async () => {
    if (viewerId)
      axios
        .get(`${baseURL}public-user-config`, {
          headers: {
            userid: viewerId,
          },
        })
        .then((res) => {
          setConfig(res.data);
        })
        .catch((err) => console.log("Err", err));
  }, [viewerId]);

  useEffect(() => {
    if (params) getId();
  }, [params, getId]);

  useEffect(() => {
    if (viewerId) {
      getConfig();
    }
  }, [viewerId, getConfig]);

  return (
    <Box
      as="div"
      // sx={{ background: `url(${bgPattern})`, height, overflow: "auto" }}
      sx={{ height, overflow: "hidden" }}
    >
      {config && <NoSlideShow config={config} viewerId={viewerId} />}
      {config && <TopBottom config={config} viewerId={viewerId} />}
    </Box>
  );
};

export default AllCameraListContainer;

const NoSlideShow = ({ config, viewerId }) => {
  if (config[0]?.slideShowPosition !== "NONE") return null;
  return (
    <Grid container>
      <Grid item xs={12}>
        <RightSide config={config} viewerId={viewerId} />
      </Grid>
    </Grid>
  );
};

const TopBottom = ({ config, viewerId }) => {
  if (config[0].slideShowPosition === "NONE") return null;
  if (config[0].slideShowPosition === "TOP") {
    return (
      <>
        <LeftSide config={config} viewerId={viewerId} />
        <RightSide config={config} viewerId={viewerId} />
      </>
    );
  }
  if (config[0].slideShowPosition === "BOTTOM") {
    return (
      <>
        <RightSide config={config} viewerId={viewerId} />
        <LeftSide config={config} viewerId={viewerId} />
      </>
    );
  }
  if (config[0].slideShowPosition === "RIGHT") {
    return (
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <RightSide1 config={config} viewerId={viewerId} />
        <LeftSide1 config={config} viewerId={viewerId} />
      </Box>
    );
  }
  if (config[0].slideShowPosition === "LEFT") {
    return (
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <LeftSide1 config={config} viewerId={viewerId} />
        <RightSide1 config={config} viewerId={viewerId} />
      </Box>
    );
  }
};
