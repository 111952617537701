import React from "react";
import Card from "../../Card";

import TopGeneral from "./TopGeneral";

const General = () => {
  return (
    <div>
      <Card title={"General"} color="#81B0D2">
        <TopGeneral />
      </Card>
    </div>
  );
};

export default General;
