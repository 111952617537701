/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import useInnerSize from "../../hooks/useInnerSize";
import CheckInLogo from "../../assets/images/IMG_2822.png";
import CheckOutLogo from "../../assets/images/IMG_2823.png";
import InterviewLogo from "../../assets/images/IMG_2824.png";
import ScheduledLogo from "../../assets/images/IMG_2825.png";
import { MessageSound } from "./sounds";

const Item = ({ item, logo, background, text, height, sound, diff, muted }) => {
  const [resFSize, setResFSize] = useState(0);
  const [resFMSize, setResFMSize] = useState(0);
  const [resMTop, setResMTop] = useState(0);
  const [resLHeight, setResLHeight] = useState(0);
  const [imageWidth, setImageWidth] = useState("90%");

  const { width } = useInnerSize();
  useEffect(() => {
    calcFontSize();
  }, [resFSize, text, width, height]);

  const calcFontSize = () => {
    let area = height * width * 0.65;
    let ourTextLen = item.dogName;
    if (ourTextLen < 12) ourTextLen = 12;
    let charArea = Math.floor(0.65 * Math.sqrt(area / ourTextLen));
    // let fSize = "5em";

    // 12 letter = 10em
    // 23 letter = 8em
    // 39 letter = 5em
    // 50 letter = 3em

    let maxLetters = 0;
    item.dogName.split(" ").forEach((d) => {
      if (d.length > maxLetters) {
        maxLetters = d.length;
      }
    });
    let fSize = Math.floor(0.3 * Math.sqrt(area / maxLetters));
    // let fSize = maxLetters < 13? "6em": maxLetters < 30? "3em": maxLetters < 40? "5em": "30em";
    // 10em - 6 letras
    // 8em - 8 letras
    // 6em - 10 letras
    // 4em - 12 letras

    // 6L - 10em
    // 7L - X = 11.66

    // 10L - 6em
    // 7L - X = 4.2

    let ourTextLen2 = item.message.length;
    if (ourTextLen2 < 12) ourTextLen2 = 12;
    let charArea2 = Math.floor(0.3 * Math.sqrt(area / ourTextLen2));
    let fSize2 = charArea2 + "px";

    let marTop = charArea * 0.005;
    let lHeight = charArea - marTop * 10;
    setResFSize(fSize);
    setResFMSize(fSize2);
    setResMTop(marTop);
    setResLHeight(lHeight);
    if (width < 2000) {
      setImageWidth("60%");
    }
  };

  useEffect(() => {
    const audio = new Audio(MessageSound(sound));
    if (muted === 0 || muted === "0") {
      audio.play();
    }
  }, [text]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: height,
      }}
    >
      <Box
        as={"div"}
        sx={{
          background: background,
          width: "100%",
          height: "20px",
          maxHeight: 100,
          minHeight: 20,
          boxSizing: "border-box",
          textAlign: "center",
          py: "10px",
          color: "#fff",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: "20px",
          paddingRight: "15%",
        }}
      >
        <Box as={"div"}></Box>
        <Box as={"h4"}>New message</Box>
        {/* <Box as={"h2"}>
          {moment(new Date(item.lastSent)).add(3,'hours').format("h:mm A, MMM Do YYYY")}
        </Box> */}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          background: background,
          width: "100%",
          height: height - 200,
        }}
      >
        <Box
          component="img"
          sx={{
            width: imageWidth,
            objectFit: "cover",
            height: "100%",
            marginTop: "-20px",
            float: "left",
            // maxWidth: "950px",
          }}
          alt="logo"
          src={
            logo !== null
              ? logo
              : item.category === "checkin"
              ? CheckInLogo
              : item.category === "checkout"
              ? CheckOutLogo
              : item.category === "interview"
              ? InterviewLogo
              : ScheduledLogo
          }
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#000",
            width: "50%",
            height: "100%",
            background: background,
          }}
          padding={0}
          px={3}
        >
          <Typography
            sx={{
              fontSize: resFSize,
              marginTop: resMTop + "px",
              color: "#fff",
              textAlign: "left",
            }}
            fontFamily="Oswald"
            // lineHeight={resLHeight + "px"}
            fontWeight={200}
            letterSpacing={7}
          >
            {item.dogName}
          </Typography>
        </Box>
        <br></br>
      </Box>
      <Box
        sx={{
          height: "200px",
          bottom: "0px",
          backgroundColor: "yellow",
          zIndex: "999999999",
          fontSize: "20px",
          overflow: "hidden",
          marginTop: "-20px",
          width: width * 0.73,
        }}
        padding={0}
        px={3}
        className={diff > 10 ? "none-blink" : "blink"}
      >
        <Typography
          sx={{
            fontSize: resFMSize,
            marginTop: resMTop + "px",
            textAlign: "center",
            color: "#000",
            padding: "10px",
          }}
          fontFamily="Oswald"
          lineHeight={resLHeight + "px"}
          fontWeight={500}
          letterSpacing={7}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default Item;
