import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import * as portals from "react-reverse-portal";

const SingleBackCamera = ({ singleCamera }) => {
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              height: "100vh",
              overflow: "hidden",
              border: "1px solid black",
            }}
          >
            <portals.OutPortal node={singleCamera} />
          </Box>
        </Grid>
      </Grid>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "900px",
        }}
      ></div>
    </div>
  );
};

export default SingleBackCamera;
