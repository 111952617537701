import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

import logo from "../../assets/images/newlogo.png";
import Weather from "./Weather";
import moment from "moment";
import SingleCameraContainer from "../singleCamera/SingleCameraContainer";

const RightSideArea = ({
  isMessage,
  location,
  cameraSplit,
  primaryCamera,
  secondaryCamera,
}) => {
  const [cTime, setCTime] = useState("");
  useEffect(() => {
    const timer = setInterval(() => {
      setCTime(moment(new Date()).format("h:mm A"));
    }, 1000);

    return () => clearInterval(timer);
  }, []);
  return (
    <Grid item container sx={styles.main} xs={12}>
      <Box>
        <Box component="div" sx={styles.imageWrapper}>
          <Box
            component="img"
            sx={styles.image}
            alt="logo"
            src={logo}
            width={"70%"}
          />
        </Box>
        <Weather location={location} />
        <Box
          sx={{
            fontSize: 37,
            textAlign: "center",
            color: "#fff",
          }}
          as={"h2"}
        >
          {cTime}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          width: "100%",
          overflow: "hidden",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "-200px",
        }}
      >
        <SingleCameraContainer
          //For future reference:
          // false false = null
          // false true = primaryCamera
          // true false = primaryCamera
          // true true = primaryCamera

          // false false = null
          // false true = null
          // string false = null
          // string true = secondaryCamera

          primaryCamera={
            cameraSplit === false && isMessage === false ? null : primaryCamera
          }
          secondaryCamera={
            cameraSplit !== false && isMessage === true ? secondaryCamera : null
          }
        />
      </Box>
    </Grid>
  );
};

export default RightSideArea;

const styles = {
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#547189",
    pt: "30px",
    height: {
      xs: "auto",
      md: "100vh",
    },
  },
  imageWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};
