import { LoadingButton } from "@mui/lab";
import { Box, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  useGetConfigsQuery,
  useUpdateConfigMutation,
} from "../../../../features/userConfig/userConfigApiSlice";
import SelectSound from "../SelectSound";
import { MessageSound } from "../../../BackDisplay/sounds";
import playImage from "../../../../assets/images/playbutton.jpg";
import playHoverImage from "../../../../assets/images/playbuttonhover.jpg";
import playClickImage from "../../../../assets/images/playbuttonclick.jpg";

const TopQuickSend = () => {
  const { data, isSuccess } = useGetConfigsQuery();
  const [updateConfig, { isLoading }] = useUpdateConfigMutation();
  const [over, setOVer] = useState("out");

  const { register, setValue, watch, handleSubmit } = useForm({
    quickSendSound: "",
    quickSendDuration: 0,
  });

  useEffect(() => {
    if (isSuccess && data.length > 0) {
      setValue("quickSendSound", data[0].quickSendSound);
      setValue("quickSendDuration", data[0].quickSendDuration);
    }
  }, [isSuccess, data, setValue]);

  const submitHandler = (values) => {
    updateConfig({ id: data[0].id, data: values });
  };

  const playSound = () => {
    let audio = new Audio(MessageSound(watch("quickSendSound")));
    audio.play();
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        gap={4}
        sx={{ py: "12px" }}
        alignItems={"center"}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ flex: 1, width: { xs: "100%", sm: "200px" } }}>
            <SelectSound
              value={watch("quickSendSound")}
              setValue={(v) => setValue("quickSendSound", v)}
            />
          </Box>
          <Box
            onClick={() => playSound()}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Box
              component="img"
              sx={{ cursor: "pointer" }}
              alt="logo"
              src={
                over === "down"
                  ? playClickImage
                  : over === "in"
                  ? playHoverImage
                  : playImage
              }
              onMouseDown={() => setOVer("down")}
              onMouseOver={() => setOVer("in")}
              onMouseOut={() => setOVer("out")}
              mx={3}
            />
          </Box>
        </div>
        <div>
          <Box sx={{ flex: 1 }}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              label="Duration (sec)"
              variant="outlined"
              placeholder={"input here"}
              fullWidth
              {...register("quickSendDuration", {
                required: "This field is required!",
              })}
            />
          </Box>
        </div>
        <div style={{ width: 120 }}>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            type="submit"
            fullWidth
          >
            Save
          </LoadingButton>
        </div>
      </Stack>
    </form>
  );
};

export default TopQuickSend;
