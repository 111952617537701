import React from 'react';
import DefaultImagesContainer from '../../components/admin/defautlImages/DefaultImages';
const DefaultImages = () => {
  return (
    <>
      <DefaultImagesContainer />
    </>
  );
};

export default DefaultImages;
