import React, { useMemo, useRef } from "react";
import { useInnerSize } from "../../hooks/useInnerSize";
import ApiItem from "./ApiItem";
import FadeInOut from "../FadeInOut";

const ApiContent = ({ config, muted, messages, show }) => {
  const { height: windowHeight } = useInnerSize();
  const scrollRef = useRef(null);
  const height = useMemo(() => {
    const calculateHeight = windowHeight / 1;
    if (calculateHeight > windowHeight / 7) return calculateHeight;
    return windowHeight / 7;
  }, [windowHeight]);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      scrollRef.current.scrollTo(0, 0);
    }
  };

  return (
    <div
      // style={{ height: windowHeight }}
      className={"no-scrollbar"}
      ref={scrollRef}
      onScroll={handleScroll}
    >
      <FadeInOut show={show} duration={500}>
        {!messages ? null : (
          <div>
            <ApiItem
              muted={muted}
              item={messages}
              background={
                messages?.category === "checkin"
                  ? "#489462"
                  : messages?.category === "checkout"
                  ? "#9C2136"
                  : messages?.category === "interview"
                  ? "#F4364C"
                  : "#81B0D2"
              }
              logo={messages?.imageUrl}
              key={messages?.id}
              text={messages?.message}
              height={height}
              sound={
                messages?.category === "checkin"
                  ? config[0]?.checkInSound
                  : messages?.category === "checkout"
                  ? config[0]?.checkOutSound
                  : messages?.category === "interview"
                  ? config[0]?.interviewSound
                  : messages?.category === "general"
                  ? config[0]?.generalSound
                  : config[0]?.scheduledSound
              }
              diff={messages?.diff}
            />
          </div>
        )}
      </FadeInOut>
    </div>
  );
};

export default ApiContent;
