import axios from "axios";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

export const WeatherIcons = {
  "000": "/icons/new.svg",
  "01d": "/icons/sunny.svg",
  "01n": "/icons/night.svg",
  "02d": "/icons/day.svg",
  "02n": "/icons/cloudy-night.svg",
  "03d": "/icons/cloudy.svg",
  "03n": "/icons/cloudy.svg",
  "04d": "/icons/perfect-day.svg",
  "04n": "/icons/cloudy-night.svg",
  "09d": "/icons/rain.svg",
  "09n": "/icons/rain-night.svg",
  "10d": "/icons/rain.svg",
  "10n": "/icons/rain-night.svg",
  "11d": "/icons/storm.svg",
  "11n": "/icons/storm.svg",
  "13n": "/icons/snow.svg",
  "50d": "/icons/mist.svg",
  "50n": "/icons/mist.svg",
};
const Weather = ({location}) => {
  const [weatherData, setWeatherData] = useState([]);

  useEffect(() => {
    if (location) {
      axios
        .get(
          `https://api.openweathermap.org/data/2.5/weather?q=${
            location.split(",")[0]
          }&appid=450a9d622a56bff861d328ffbea10a4b`
        )
        .then((response) => {
          setWeatherData(response.data);
        });
    }
  }, [location]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {weatherData?.main && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
            }}
            fontFamily="Lato"
            textTransform="uppercase"
            ml={2}
          >
            <Box
              sx={{ textTransform: "uppercase", fontSize: "14px" }}
              fontWeight={700}
            >
              {location.split(",")[0]}
            </Box>
            <Box sx={{ fontSize: "14px" }} fontWeight={400}>
              WEATHER
            </Box>
          </Box>
          <Box
            component="img"
            sx={styles.image}
            alt="logo"
            src={WeatherIcons[weatherData?.weather[0].icon]}
            mx={3}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            fontFamily="Lato"
            fontWeight={400}
            color="#fff"
            mr={2}
          >
            <Box
              sx={{
                fontSize: "26px",
              }}
            >
              {weatherData?.main
                ? (((weatherData?.main?.temp - 273.15) * 9) / 5 + 32).toFixed(
                    0
                  ) + `°F`
                : ""}
            </Box>
            <Box
              sx={{
                fontSize: "14px",
              }}
            >
              {weatherData?.weather[0]
                ? weatherData?.weather[0].description
                : ""}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Weather;

const styles = {
  main: {
    background: `linear-gradient(139deg,#e1d0e4,#e1d0e4, #fcf9fd)`,
    py: "30px",
    height: {
      xs: "auto",
      md: "100vh",
    },
  },
  imageWrapper: {
    height: 300,
    width: 350,
    maxHeight: { xs: 233, md: 350 },
    maxWidth: { xs: 350, md: 300 },
  },
  image: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
    maxHeight: 50,
    maxWidth: 50,
  },
};
