
import axiosInstance from "../../utils/axiosInstance";
import { getAuthUserId } from "../../utils/auth";
import axios from "axios";
import configData from "../../config.json";
import { getToken } from "../../utils/token";

export async function getPhotosApi() {
  const token = getToken();
  const baseURL = configData.SERVER_URL;
  const userId = getAuthUserId();

  const axiosIns = axios.create({
    baseURL,
    headers: {
      userid: userId,
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json",
    },
  });
  const temp = await axiosIns.get("/slides");
  return temp;
  //return axiosInstance.get('/slides',{headers: {userid: getAuthUserId(),},});
}
export async function getPhotoApi(id) {
  return axiosInstance.get(`/slides/${id}`, {
    headers: {
      userid: getAuthUserId(),
    },
  });
}
export async function deletePhotosApi(id) {
const baseURL = configData.SERVER_URL;
  const userId = getAuthUserId();

  const token = getToken();
  const axiosIns = axios.create({
    baseURL,
    headers: {
      userid: userId,
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data",
    },
  });
  const temp = await axiosIns.delete(`/slides/${id}`);
  return temp;
  //return axiosInstance.delete(`/slides/${id}`, {
    //headers: {
      //userid: getAuthUserId(),
    //},
  //});
}
export async function addPhotosApi(data) {
const token = getToken();
  const baseURL = configData.SERVER_URL;
  const userId = getAuthUserId();

  const axiosIns = axios.create({
    baseURL,
    headers: {
      userid: userId,
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "multipart/form-data",
    },
  });
  const temp = await axiosIns.post("/slides",data);
  return temp;
  //return axiosInstance.post(`/slides/`, data, {headers: {'content-type': 'multipart/form-data',userid: getAuthUserId(),},});
}
