import { Box, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import { useState } from 'react';
import { FaLaptopCode, FaCheck, FaPen, FaTimes, FaTrashAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { deleteStreamAsync, fetchStreamAsync } from '../../../../features/stream/thunks';
import EditCamera from './EditCamera';

const columns = [
  { field: 'cameraName', headerName: 'Camera Name', flex: 1 },
  {
    field: 'isGroup',
    headerName: 'Is Group',
    width: 200,
    renderCell: ({ row }) => {
      if (row?.isGroup) {
        return (
          <Box as='div' sx={{ color: 'green' }}>
            <FaCheck />
          </Box>
        );
      }

      return (
        <Box as='div' sx={{ color: 'red' }}>
          <FaTimes />
        </Box>
      );
    },
  },
  {
    field: 'backDisplay',
    headerName: 'Show backDisplay',
    width: 200,
    renderCell: ({ row }) => {
      if (row?.backDisplay) {
        return (
          <Box as='div' sx={{ color: 'green' }}>
            <FaLaptopCode />
          </Box>
        );
      }
      return (
        <Box as='div' sx={{ color: 'red' }}>
          <FaTimes />
        </Box>
      );
    },
  },
  {
    headerName: 'Action',
    field: 'action',
    width: 120,
    sortable: false,
    renderCell: ({ row }) => {
      const removeHandler = () => {
        row.dispatch(deleteStreamAsync(row.id));
      };
      const openModal = () => {
        row.setOpen(true);
        row.setSelectedId(row.id);
      };

      return (
        <>
          <Stack direction='row' gap={2} sx={{ fontSize: '20px' }}>
            <Box
              sx={{
                cursor: 'pointer',
                color: 'gray',
                transition: 'all 0.5s',
                '&:hover': {
                  color: '#6087D4',
                },
              }}
              onClick={openModal}
            >
              <FaPen />
            </Box>
            <Box
              onClick={removeHandler}
              sx={{
                cursor: 'pointer',
                color: 'gray',
                transition: 'all 0.5s',
                '&:hover': {
                  color: '#F46969',
                },
              }}
            >
              <FaTrashAlt />
            </Box>
          </Stack>
        </>
      );
    },
  },
];

export default function Table({ value }) {
  const [itemPerPage, setItemPerPage] = useState(10);
  const [selectedId, setSelectedId] = React.useState(null);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  
  const { data: streams } = useSelector((state) => state.stream);
  
  React.useEffect(() => {
    dispatch(fetchStreamAsync());
  }, [dispatch]);  
  const streamSetup = React.useMemo(() => {
    if (streams.length === 0) return [];

    return streams.reduce((acc, cur) => {
      if (!value) {
        acc.push({ ...cur, dispatch, setOpen, setSelectedId });
      } else {
        if (cur.cameraName.toLowerCase().includes(value.toLowerCase())) {
          acc.push({ ...cur, dispatch, setOpen, setSelectedId });
        }
      }
      return acc;
    }, []);
  }, [streams, dispatch, value]);

  return (
    <Box sx={{ mt: 4 }}>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Box sx={{ flexGrow: 1 }}>
          <DataGrid
            rows={streamSetup}
            columns={columns}
            disableSelectionOnClick
            disableColumnMenu
            pageSize={itemPerPage}
            onPageSizeChange={(newPageSize) => setItemPerPage(newPageSize)}
            rowsPerPageOptions={[5, 10, 20, 30, 50]}
            pagination
            style={{ border: 'none' }}
            autoHeight
          />
        </Box>
      </Box>
      {selectedId && (
        <EditCamera
          open={open}
          setOpen={(result) => {
            setOpen(result);
            setSelectedId(null);
          }}
          id={selectedId}
        />
      )}
    </Box>
  );
}
