import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addMessageApi,
  deleteMessagesApi,
  getArchiveApi,
  getMessagesApi,
  updateMessageApi,
  getArchivesApi,
} from "./messageAPI";

export const fetchMessagesAsync = createAsyncThunk(
  "message/fetch",
  async () => {
    let res = await getMessagesApi();
    let res2 = await getArchivesApi();
    // Add 'isArchived' flag to each message
    let messagesWithFlag = res.data.map((message) => ({
      ...message,
      isArchived: false,
    }));

    // Add 'isArchived' flag to each archived message
    let archivesWithFlag = res2.data.map((archive) => ({
      ...archive,
      isArchived: true,
    }));

    return [...messagesWithFlag, ...archivesWithFlag];
  }
);
export const deleteMessageAsync = createAsyncThunk(
  "message/delete",
  async (id) => {
    const res = await deleteMessagesApi(id);
    return res.data;
  }
);
export const addMessageAsync = createAsyncThunk("message/add", async (data) => {
  const res = await addMessageApi(data);
  //messages added by socketio to Archive table, but fetched through API.
  const { data: message } = await getArchiveApi(res.data.id);
  return message;
});
export const updateMessageAsync = createAsyncThunk(
  "message/update",
  async ({ id, data }) => {
    const { data: message } = await updateMessageApi({ id, data });
    return message;
  }
);
