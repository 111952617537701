import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Stack,
  Checkbox,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { inactiveEditMode } from "../../../features/message/messageSlice";
import {
  addMessageAsync,
  fetchMessagesAsync,
  updateMessageAsync,
} from "../../../features/message/thunks";
import { getAuthUserId } from "../../../utils/auth";
import DaysSelect from "./DaysSelect";
import SelectMessage from "./SelectMessage";
import SuggestedModal from "./SuggestedModal";
import { FaCaretRight } from "react-icons/fa";

const CreateForm = () => {
  const { editMode, editing, type } = useSelector((state) => state.messages);

  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {
      days: [],
      message: "",
      reoccur: "1",
      dateTime: null,
      duration: 90,
    },
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (editMode && type === "schedule") {
      const { dateTime, message, duration, days } = editing;
      console.log("dt", dateTime);
      console.log("days", days);
      let dd;
      if (days == null) {
        dd = [];
      } else {
        dd = days?.toString()?.split("");
      }
      console.log(dd);
      setValue("dateTime", dateTime.replace("T", " ").substring(0, 19));
      setValue("message", message);
      setValue("duration", duration);
      setValue("days", dd);
    }
  }, [editMode, editing, setValue, type]);

  useEffect(() => {
    return () => {
      dispatch(inactiveEditMode());
    };
  }, [dispatch]);

  const generateDaysInt = (days) => {
    const suffix = days.includes("0") ? "0" : "";

    return parseInt(days.sort((a, b) => a - b).join("") + suffix);
  };
  const submitHandler = (values) => {
    const data = {
      ...values,
      userId: getAuthUserId(),
      days: generateDaysInt(values.days),
      schedule: true,
      dateTime: moment(values.dateTime).format("YYYY-MM-DD HH:mm:ss"),
      // dateTime: moment(values.dateTime).format("YYYY-MM-DD\THH:mm:ss.000")+'Z'
    };
    const handler = editMode
      ? updateMessageAsync({ id: editing.id, data })
      : addMessageAsync(data);

    dispatch(handler);
    reset();
    setTimeout(() => {
      dispatch(fetchMessagesAsync());
    }, 2000);
  };

  const dayChangeHandler = (value) => {
    setValue("days", value);
  };

  const cancelUpdateHandler = (e) => {
    e.preventDefault();
    dispatch(inactiveEditMode());
    reset();
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const formatDateIgnoreTimezone = (dateString) => {
    const date = new Date(dateString);

    // Function to add leading zero to single digit numbers
    const pad = (num) => num.toString().padStart(2, "0");

    // Construct the format ignoring the timezone
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // getMonth() returns 0-11
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    // Return the formatted date and time
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const [isOpen, setIsOpen] = useState(false);
  const [checked, setChecked] = useState(true);
  return (
    <div>
      <form onSubmit={handleSubmit(submitHandler)}>
        <Grid container>
          <Grid xs={12} md={4} sx={{ order: 1 }}>
            <Box sx={{ mr: { md: "10px" } }}>
              {editMode ? (
                <TextField
                  placeholder="Automated Messages:"
                  multiline
                  label="Message"
                  rows={7}
                  size="small"
                  fullWidth
                  {...register("message", {
                    required: "This field is required!",
                    maxLength: {
                      value: 90,
                      message: "Maximum 90 characters allowed!",
                    },
                  })}
                />
              ) : (
                <SelectMessage
                  value={watch("message")}
                  setValue={(v) => setValue("message", v)}
                />
              )}

              <Stack
                direction="row"
                justifyContent={errors?.message ? "space-between" : "end"}
              >
                {errors?.message && (
                  <FormHelperText sx={{ color: "red" }}>
                    {errors.message.message}
                  </FormHelperText>
                )}
                {editMode && watch("message")?.length <= 90 && (
                  <Box>
                    <FormHelperText sx={{ color: "red" }}>
                      {90 - watch("message").length} characters left
                    </FormHelperText>
                  </Box>
                )}
              </Stack>
            </Box>
            <br></br>
            <Box width={"98%"}>
              <Button
                color={"secondary"}
                variant="outlined"
                style={{ height: "37px" }}
                size="small"
                onClick={() => setIsOpen(true)}
                fullWidth
              >
                Available Messages <FaCaretRight style={{ paddingLeft: 10 }} />
              </Button>
            </Box>
          </Grid>
          <Grid xs={12} md={4} sx={{ order: { xs: 3, md: 2 } }}>
            <Stack sx={{ mx: { md: "10px" } }} direction="column" gap={2}>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    renderInput={(params) => (
                      <TextField {...params} label="Date and Time" fullWidth />
                    )}
                    label="DateTimePicker"
                    value={watch("dateTime")}
                    {...register("dateTime", {
                      required: "This field is required!",
                    })}
                    onChange={(newValue) => {
                      // console.log(newValue);
                      // console.log();
                      setValue("dateTime", formatDateIgnoreTimezone(newValue));
                      // setValue(newValue);
                      if (errors?.dateTime) {
                        clearErrors("dateTime");
                      }
                    }}
                  />
                  {/* <DateTimePicker
                  {...register("dateTime", {
                    required: "This field is required!",
                  })}
                  minDate={today}
                  value={watch("dateTime")}
                  onChange={(newValue) => {
                    
                    setValue("dateTime", newValue);
                    if (errors?.dateTime) {
                      clearErrors("dateTime");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Date and Time" fullWidth />
                  )}
                /> */}
                </LocalizationProvider>
                {errors?.dateTime && (
                  <FormHelperText sx={{ color: "red" }}>
                    {errors.dateTime.message}
                  </FormHelperText>
                )}
              </Box>

              <Box text={"Duration"} width={"100%"}>
                <TextField
                  placeholder=""
                  label="Duration"
                  size="small"
                  type="number"
                  {...register("duration", {
                    required: "This field is required!",
                  })}
                  fullWidth
                />
              </Box>
              <Stack direction={"row"} gap={1}>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ width: 120 }}
                >
                  {editMode ? "Update" : "Add"}
                </Button>
                {editMode && (
                  <Button
                    onClick={cancelUpdateHandler}
                    type="button"
                    variant="contained"
                    color="warning"
                    style={{ width: 120 }}
                  >
                    Cancel
                  </Button>
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid
            xs={12}
            md={4}
            sx={{ order: { xs: 2, md: 3 }, mb: { xs: 1, md: 0 } }}
          >
            <Box text={"Repeat"} width={"100%"} height={"70px"}>
              <Checkbox
                checked={checked}
                onChange={handleChange}
                color="secondary"
              />{" "}
              Repeat this message
            </Box>
            {checked ? (
              <Box sx={{ width: "100%" }}>
                <DaysSelect days={watch("days")} setDays={dayChangeHandler} />
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </form>
      <SuggestedModal open={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default CreateForm;
