import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
const ConfigureLayout = ({ children }) => {
  const { is_admin } = useSelector((state) => state?.auth?.user);
  const { isProxy } = useSelector((state) => state?.auth?.proxy);
  return (
    <>
      <Typography
        variant="h4"
        sx={{
          textTransform: "uppercase",
        }}
        component={"h1"}
        fontFamily="MissMagnolia"
        letterSpacing={2}
      >
        Configure Pupdates
      </Typography>

      <Box as="div" sx={{ mt: "15px", pb: "0" }}>
        <Box
          as="div"
          sx={{
            display: "flex",
            gap: "20px",
            flexWrap: "wrap",
            fontFamily: `Lato`,
            fontWeight: 300,
            letterSpacing: 1,
          }}
        >
          <LinkItem text="Messages & Sounds" url="/configure/messages-sounds" />
          <LinkItem text="Streams" url="/configure/streams" />
          {is_admin && isProxy && (
            <LinkItem text="Displays" url="/configure/displays" />
          )}
          <LinkItem text="Slide Show" url="/configure/slide-show" />
        </Box>
      </Box>
      <Divider sx={{ width: "80%" }} />
      <div>{children}</div>
    </>
  );
};

const LinkItem = ({ url, text }) => {
  const { pathname } = useLocation();

  return (
    <Box
      as={Link}
      sx={{
        display: "block",
        textDecoration: "none",
        color: pathname === url ? "#81b1d9" : "#4f4f4f",
        fontSize: "27px",
        transition: "all 0.5s",
        "&:hover": {
          color: "#81b1d9",
        },
        borderBottom: pathname === url ? "2px solid #81b1d9" : 0,
        pb: "5px",
        pr: "15px",
        fontWeight: 500,
        fontFamily: "MissMagnolia",
        textTransform: "uppercase",
        letterSpacing: 2,
      }}
      to={url}
    >
      {text}
    </Box>
  );
};

export default ConfigureLayout;
