import { useState, useEffect, useCallback } from "react";
import configData from "../../config.json";
import { Grid } from "@mui/material";
import MessageListingDisplay from "./MessageListingDisplay";
import RightSideArea from "./RightSideArea";
import { useParams } from "react-router-dom";
import axios from "axios";
import LargeMessageOverlay from "./LargeMessageOverlay";
import useMessages from "../../hooks/useMessages";

const MessageContent = ({
  cameraUrl,
  muted,
  splitted,
  cam,
  primaryCamera,
  secondaryCamera,
}) => {
  const { params } = useParams();
  const [location, setLocation] = useState("Houston, tx");
  const [viewerId, setViewerId] = useState(0);
  const [config, setConfig] = useState();
  const [isMessage, setIsMessage] = useState(false);

  const { messages, apiMessages, setApiMessages } = useMessages({
    viewerId: viewerId,
  });

  const baseURL = configData.SERVER_URL;

  const getLocation = useCallback(async () => {
    axios
      .get(`${baseURL}back-display?account=${params}`)
      .then((res) => {
        setLocation(res.data.location);
      })
      .catch((err) => {
        console.log("Err::", err);
        setLocation("Houston, tx");
      });
  }, [setLocation, baseURL, params]);

  const getViewerId = useCallback(async () => {
    axios
      .get(`${baseURL}back-display?account=${params}`)
      .then((res) => {
        setViewerId(res.data.id);
      })
      .catch((err) => {
        console.log("Err::", err);
        setViewerId(0);
      });
  }, [setViewerId, baseURL, params]);

  const getConfig = useCallback(async () => {
    axios
      .get(`${baseURL}public-user-config`, {
        headers: {
          userid: viewerId,
        },
      })
      .then((res) => {
        setConfig(res.data);
      })
      .catch((err) => console.log("Err", err));
  }, [setConfig, baseURL, viewerId]);

  useEffect(() => {
    if (viewerId !== 0) getConfig();
  }, [viewerId, getConfig]);

  useEffect(() => {
    if (params !== undefined) {
      getViewerId();
      getLocation();
    } else {
      setViewerId(0);
      setLocation("Houston, tx");
    }
  }, [params, getLocation, getViewerId]);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={8} lg={9}>
        <LargeMessageOverlay
          config={config}
          muted={muted}
          messages={apiMessages}
          setMessages={setApiMessages}
        />
        <MessageListingDisplay
          setIsMessage={setIsMessage}
          isMessage={isMessage}
          viewerId={viewerId}
          config={config}
          muted={muted}
          messages={messages}
          splitted={splitted}
          primaryCamera={primaryCamera}
          secondaryCamera={secondaryCamera}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={3}>
        <RightSideArea
          isMessage={isMessage}
          location={location}
          cameraSplit={cam ?? false}
          primaryCamera={primaryCamera}
          secondaryCamera={secondaryCamera}
        />
      </Grid>
    </Grid>
  );
};
export default MessageContent;
