import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
import {
  useGetConfigsQuery,
  useUpdateConfigMutation,
} from "../../../../features/userConfig/userConfigApiSlice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["30", "60", "90", "120", "180", "240"];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectPlaceholder() {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [updateConfig] = useUpdateConfigMutation();
  const { data, isSuccess } = useGetConfigsQuery();
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    updateConfig({ id: data[0].id, data: { slideShowDuration: value } });
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  React.useEffect(() => {
    if (isSuccess) {
      setPersonName([data[0].slideShowDuration]);
    }
  }, [isSuccess, data]);
  return (
    <div>
      <FormControl sx={{ width: 200 }}>
        <Select
          displayEmpty
          size="small"
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>90</em>;
            }

            return selected.join(", ");
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
