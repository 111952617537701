import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import * as portals from "react-reverse-portal";

const SingleCameraContainer = ({ primaryCamera, secondaryCamera }) => {
  return (
    <div
      style={{
        width: "110%",
      }}
    >
      {primaryCamera && secondaryCamera ? (
        <div style={{ height: 500, width: "100%" }}>
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  height: 250,
                  overflow: "hidden",
                  border: "0px solid black",
                }}
              >
                <portals.OutPortal node={secondaryCamera} />
              </Box>
              <Box
                sx={{
                  height: 250,
                  overflow: "hidden",
                  border: "0px solid black",
                }}
              >
                <portals.OutPortal node={primaryCamera} />
              </Box>
              <Box
                sx={{
                  height: 250,
                  overflow: "hidden",
                  border: "0px solid black",
                }}
              ></Box>
            </Grid>
          </Grid>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "900px",
            }}
          ></div>
        </div>
      ) : (
        <div style={{ height: 250, width: "100%" }}>
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  height: 250,
                  overflow: "hidden",
                  border:
                    primaryCamera || secondaryCamera
                      ? "1px solid black"
                      : "none",
                }}
              >
                {primaryCamera && <portals.OutPortal node={primaryCamera} />}
                {secondaryCamera && (
                  <portals.OutPortal node={secondaryCamera} />
                )}
              </Box>
            </Grid>
          </Grid>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "900px",
            }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default SingleCameraContainer;
