import { LoadingButton } from "@mui/lab";
import { Box, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  useGetConfigsQuery,
  useUpdateConfigMutation,
} from "../../../../features/userConfig/userConfigApiSlice";
import Card from "../../Card";

const ApiMessage = () => {
  const { data, isSuccess } = useGetConfigsQuery();
  const [updateConfig, { isLoading }] = useUpdateConfigMutation();

  const {
    register,
    setValue,
    handleSubmit,
  } = useForm({
    apiMessageDuration: 0,
  });

  useEffect(() => {
    if (isSuccess && data.length > 0) {
      setValue("apiMessageDuration", data[0].apiMessageDuration);
    }
  }, [isSuccess, data, setValue]);

  const submitHandler = (values) => {
    updateConfig({ id: data[0].id, data: values });
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Card title={"Large Camper Photo Display"} color="#489462">
        <Stack
          direction={{ xs: "column", sm: "row" }}
          gap={4}
          sx={{ py: "12px" }}
          alignItems={"center"}
        >
          <div>
            <Box sx={{ flex: 1 }}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                type="number"
                label="Duration (sec)"
                variant="outlined"
                placeholder={"input here"}
                fullWidth
                {...register("apiMessageDuration", {
                  required: "This field is required!",
                })}
              />
            </Box>
          </div>
          <div style={{ width: 120 }}>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              type="submit"
              fullWidth
              //   sx={{ background: '#82da73' }}
            >
              Save
            </LoadingButton>
          </div>
        </Stack>
      </Card>
    </form>
  );
};

export default ApiMessage;
