import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import useInnerSize from "../../hooks/useInnerSize";
import Navigation from "./Navigation";
import UserMenu from "./UserMenu";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
const SideBar = ({ isExtend, setIsExtend, elementHeight }) => {
  const { height } = useInnerSize();
  const {
    isAuthenticated,
    user,
    proxy: { isProxy },
  } = useSelector((state) => state.auth);
  if (!isAuthenticated) return null;
  return (
    <Box
      sx={{
        position: "relative",
        pt: "20px",
        background: "#557993",
        height: height - 90,

        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        boxSizing: "border-box",
        cursor: "pointer",
      }}
    >
      <div
        style={{ position: "absolute", right: 5, top: 5, height: 20 }}
        onClick={() => setIsExtend(!isExtend)}
      >
        {!isExtend ? (
          <KeyboardDoubleArrowLeftIcon style={{ color: "white" }} />
        ) : (
          <KeyboardDoubleArrowRightIcon style={{ color: "white" }} />
        )}
      </div>
      <div>{(!user.is_admin || isProxy) && <Navigation />}</div>
      <div>
        <UserMenu />
      </div>
    </Box>
  );
};

export default SideBar;
